import { ReactNode } from "react";

import { useHydrateAtoms } from "jotai/utils";

interface HydrateAtoms {
  initialValues: any[][];
  children: ReactNode;
}

const HydrateAtoms = ({ initialValues, children }: HydrateAtoms) => {
  /*useHydrateAtoms: syncs props from page props to jotai store*/
  useHydrateAtoms(initialValues as any);

  return <>{children}</>;
};

export default HydrateAtoms;
