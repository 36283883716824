import { atomWithStorage } from "jotai/utils";

import { stringStorage } from "atoms/utils";

import { authenticationKeys } from "contexts/app";

const languageAtom = atomWithStorage<string | undefined>(authenticationKeys.language, undefined, stringStorage, {
  getOnInit: true,
});
languageAtom.debugLabel = "language";

export { languageAtom };
