import { ReactNode, useContext } from "react";

import { useTransformToken } from "components/antd/hooks";

import { App, ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";

import { LanguageContext } from "components/language-wrapper";

const appSettingsKey = "navirec-app-settings";

interface AntdConfig {
  children: ReactNode;
  designTokens: any;
}

const getAppFontSize = () => {
  if (typeof localStorage !== "undefined") {
    const settings = localStorage?.getItem(appSettingsKey);

    if (settings) {
      const parsed = JSON.parse(settings);
      return parsed?.fontSize;
    }
  }

  return 14; //default font size
};

const setAppFontSize = (size: number) => {
  localStorage.setItem(appSettingsKey, JSON.stringify({ fontSize: size }));
  window.location.reload();
};

const AntdConfiguration = ({ children, designTokens }: AntdConfig) => {
  const language = useContext(LanguageContext);
  const tokens = useTransformToken(designTokens, "light");
  const fontSize = getAppFontSize();

  return (
    <ConfigProvider
      locale={language.antdLocale}
      theme={{
        inherit: true,
        token: { colorPrimary: "#3DA066", ...tokens, fontSize },
        components: {},
      }}
    >
      <App message={{ maxCount: 1, duration: 3 }}>
        <ThemeProvider theme={tokens}>{children as any}</ThemeProvider>
      </App>
    </ConfigProvider>
  );
};
export default AntdConfiguration;
export { setAppFontSize, getAppFontSize };
