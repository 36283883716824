import { ReactNode, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

import Link from "next/link";
import { useRouter } from "next/router";

import { Trans } from "@lingui/macro";
import { Button, Col, Row, Typography } from "antd";
import styled from "styled-components";

import AppstoreIcon from "icons/appstore";
import GooglePlayIcon from "icons/googleplay";
import LogoIcon from "icons/logo";

const MobileOverLay = styled.div`
  background: #ffffff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-row {
    padding: 48px;
  }
`;

const excludePages = ["/forms/[id]/responses"];

const DetectMobileDevice = ({ children }: { children: ReactNode }) => {
  const [hideMobileBanner, setHideMobileBanner] = useState(false);

  const router = useRouter();
  const hideBannerForPages = !excludePages.some((excludeRoute) => router.pathname.startsWith(excludeRoute));
  const showMobileBanner = isMobile && !isTablet && !hideMobileBanner && hideBannerForPages;

  return (
    <>
      {showMobileBanner && (
        <MobileOverLay>
          <Row align={"middle"} justify={"center"} gutter={[16, 16]}>
            <Col span={24}>
              <LogoIcon width={100} height={100} />
            </Col>
            <Col span={24}>
              <Typography.Title level={3}>
                <Trans>Looks like you are using a mobile device</Trans>
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text>
                <Trans>
                  To manage vehicles and drivers, use route playback and other functions, download the app instead.
                </Trans>
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Link href={"https://play.google.com/store/apps/details?id=eu.gsmauto.navirec"}>
                <GooglePlayIcon />
              </Link>
            </Col>
            <Col span={24}>
              <Link href={"https://apps.apple.com/us/app/navirec/id875911396"}>
                <AppstoreIcon />
              </Link>
            </Col>
            <Col span={24}>
              <Button
                type={"default"}
                onClick={() => {
                  setHideMobileBanner(true);
                }}
              >
                Enter website
              </Button>
            </Col>
          </Row>
        </MobileOverLay>
      )}
      {!showMobileBanner && children}
    </>
  );
};

export default DetectMobileDevice;
