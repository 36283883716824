import Link from "next/link";

import { getAppFontSize } from "components/antd/config";

import {
  AppstoreAddOutlined,
  AuditOutlined,
  BellOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  FormOutlined,
  IdcardOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";

const availableMenus = (configFeatures: any, withoutPermission = false) => {
  const fontSize = getAppFontSize();
  const style = { fontSize: fontSize + 6 };

  const menus = [
    {
      key: "/map/",
      value: "/map/",
      feature: "map",
      icon: (
        <Link href={"/map/"}>
          <EnvironmentOutlined style={style} />
        </Link>
      ),
      label: t`Map`,
    },
    {
      key: "/reports/dashboard/",
      feature: "reports",
      icon: (
        <Link href={"/reports/dashboard/"}>
          <FileTextOutlined style={style} />
        </Link>
      ),
      label: t`Reports`,
      children: [
        {
          key: "/reports/dashboard/",
          feature: "reports",
          label: <Link href={"/reports/dashboard/"}>{t`Overview`}</Link>,
        },
        {
          key: "/reports/trips/",
          feature: "reports.trips",
          label: <Link href={"/reports/trips/"}>{t`Trips`}</Link>,
        },
        {
          key: "/reports/tankings/",
          feature: "reports.tankings",
          label: <Link href={"/reports/tankings/"}>{t`Tankings`}</Link>,
        },
        {
          key: "/reports/vehicle-events/",
          feature: "reports.vehicle_events",
          label: <Link href={"/reports/vehicle-events/"}>{t`Vehicle Events`}</Link>,
        },
        {
          key: "/reports/notifications/",
          feature: "reports.notifications",
          label: <Link href={"/reports/notifications/"}>{t`Notifications`}</Link>,
        },
        {
          key: "/reports/resolutions/",
          feature: "reports.resolutions",
          label: <Link href={"/reports/resolutions/"}>{t`Resolutions`}</Link>,
        },
        {
          key: "/reports/expenses/",
          feature: "reports.expenses",
          label: <Link href={"/reports/expenses/"}>{t`Expenses`}</Link>,
        },
        {
          key: "/reports/sensor-data/",
          feature: "reports.sensor_data",
          label: <Link href={"/reports/expenses/"}>{t`Sensor Data`}</Link>,
        },
      ],
    },
    {
      key: "/messaging/",
      feature: "messaging",
      icon: (
        <Link href={"/messaging/"}>
          <MessageOutlined style={style} />
        </Link>
      ),
      label: t`Messaging`,
    },
    {
      key: "/notifications/",
      feature: "notifications",
      icon: (
        <Link href={"/notifications/"}>
          <BellOutlined style={style} />
        </Link>
      ),
      label: t`Notifications`,
      children: [
        {
          key: "/notifications/",
          feature: "notifications.notifications",
          label: <Link href={"/notifications/"}>{t`Notifications`}</Link>,
        },
        {
          key: "/notifications/rules/",
          feature: "notifications.notification_rules",
          label: <Link href={"/notifications/rules/"}>{t`Notification rules`}</Link>,
        },
      ],
    },
    {
      key: "/services/",
      feature: "services",
      icon: (
        <Link href={"/services/"}>
          <AppstoreAddOutlined style={style} />
        </Link>
      ),
      label: t`Services`,
    },
    {
      key: "/reservations/",
      feature: "reservations",
      icon: (
        <Link href={"/reservations/"}>
          <CalendarOutlined style={style} />
        </Link>
      ),
      label: t`Reservations`,
    },
    {
      key: "/expenses/",
      feature: "expenses",
      icon: (
        <Link href={"/expenses/"}>
          <AuditOutlined style={style} />
        </Link>
      ),
      label: t`Expenses`,
      children: [
        {
          key: "/expenses/",
          feature: "expenses",
          label: <Link href={"/expenses/"}>{t`Expenses`}</Link>,
        },
        {
          key: "/expenses/expense-categories/",
          feature: "expenses",
          label: <Link href={"/expenses/expense-categories/"}>{t`Categories`}</Link>,
        },
        {
          key: "/expenses/expense-labels/",
          feature: "expenses",
          label: <Link href={"/expenses/expense-labels/"}>{t`Labels`}</Link>,
        },
      ],
    },
    {
      key: "/tachographs/overview/",
      feature: "tachographs",
      icon: (
        <Link href={"/tachographs/overview/"}>
          <IdcardOutlined style={style} />
        </Link>
      ),
      label: t`Tachographs`,
      children: [
        {
          key: "/tachographs/overview/",
          feature: "tachographs.overview",
          label: <Link href={"/tachographs/overview/"}>{t`Overview`}</Link>,
        },
        {
          key: "/tachographs/downloads/",
          feature: "tachographs.tachograph_downloads",
          label: <Link href={"/tachographs/downloads/"}>{t`Downloads`}</Link>,
        },
        {
          key: "/tachographs/cards/",
          feature: "tachographs.tachograph_cards",
          label: <Link href={"/tachographs/cards/"}>{t`Cards`}</Link>,
        },
        {
          key: "/tachographs/app/",
          feature: "tachographs.tachograph_downloads",
          label: <Link href={"/tachographs/app/"}>{t`Client App`}</Link>,
        },
      ],
    },
    {
      key: "/forms/",
      feature: "forms",
      icon: (
        <Link href={"/forms/"}>
          <FormOutlined style={style} />
        </Link>
      ),
      label: t`Forms`,
      children: [
        { key: "/forms/", feature: "forms.forms", label: <Link href={"/forms/"}>{t`Forms`}</Link> },
        {
          key: "/forms/responses/",
          feature: "forms.form_responses",
          label: <Link href={"/forms/responses/"}>{t`Responses`}</Link>,
        },
      ],
    },
    {
      key: "/settings/",
      value: "/settings/vehicles/",
      feature: "settings",
      icon: (
        <Link href={"/settings/vehicles/"}>
          <SettingOutlined style={style} />
        </Link>
      ),
      label: t`Settings`,
      children: [
        {
          key: "/settings/vehicles/",
          feature: "settings.vehicles",
          label: <Link href={"/settings/vehicles/"}>{t`Vehicles`}</Link>,
        },
        {
          key: "/settings/vehicle-groups/",
          feature: "settings.vehicle_groups",
          label: <Link href={"/settings/vehicle-groups/"}>{t`Vehicle Groups`}</Link>,
        },
        {
          key: "/settings/vehicle-transfers/",
          feature: "settings.vehicle_transfers",
          label: <Link href={"/settings/vehicle-transfers/"}>{t`Vehicle Transfers`}</Link>,
        },
        {
          key: "/settings/drivers/",
          feature: "settings.drivers",
          label: <Link href={"/settings/drivers/"}>{t`Drivers`}</Link>,
        },
        {
          key: "/settings/driver-groups/",
          feature: "settings.driver_groups",
          label: <Link href={"/settings/driver-groups/"}>{t`Driver Groups`}</Link>,
        },
        {
          key: "/settings/ibuttons/",
          feature: "settings.ibuttons",
          label: <Link href={"/settings/ibuttons/"}>{t`iButtons`}</Link>,
        },
        {
          key: "/settings/users/",
          feature: "settings.users",
          label: <Link href={"/settings/users/"}>{t`Users`}</Link>,
        },
        {
          key: "/settings/user-groups/",
          feature: "settings.user_groups",
          label: <Link href={"/settings/user-groups/"}>{t`User Groups`}</Link>,
        },
        {
          key: "/settings/fuel-cards/",
          feature: "settings.fuel_cards",
          label: <Link href={"/settings/fuel-cards/"}>{t`Fuel Cards`}</Link>,
        },
        {
          key: "/settings/contacts/",
          feature: "settings.contacts",
          label: <Link href={"/settings/contacts/"}>{t`Contacts`}</Link>,
        },
        {
          key: "/settings/user/information/",
          feature: "settings.user",
          label: <Link href={"/settings/user/information/"}>{t`Personal`}</Link>,
        },
      ],
    },
  ];

  if (withoutPermission) {
    return menus;
  }

  return menus
    .filter((menu) => configFeatures?.[menu.feature] === true)
    ?.map((menu) => {
      if (menu?.children) {
        menu.children = menu.children.filter((menu) => configFeatures?.[menu.feature] === true);
      }

      return menu;
    });
};

const availableUserMenuItems = (configFeatures: any, disabled = false) => {
  const menus = [
    {
      key: "/user/settings/",
      disabled: disabled,
      url: "/user/settings/",
      feature: "user.settings",
      label: <Link href={"/user/settings/"}>{t`Personal settings`}</Link>,
    },
    {
      key: "/user/authentication/",
      url: "/user/authentication/",
      feature: "user.authentication",
      label: <Link href={"/user/authentication/"}>{t`Password and login`}</Link>,
    },
    {
      key: "/user/notifications/",
      url: "/user/notifications/",
      feature: "user.notifications",
      label: <Link href={"/user/notifications/"}>{t`Notification subscriptions`}</Link>,
    },
    {
      key: "/user/sessions/",
      url: "/user/sessions/",
      feature: "user.sessions",
      label: <Link href={"/user/sessions/"}>{t`Your devices`}</Link>,
    },
    {
      key: "/user/application/",
      url: "/user/application/",
      feature: "user.settings",
      label: <Link href={"/user/application/"}>{t`Application settings`}</Link>,
    },
  ];

  return menus.filter((menu) => configFeatures?.[menu.feature] === true);
};

const availableAccountMenuItems = (configFeatures: any, canAccess = false, canManageUsers = false) => {
  const menus = [
    {
      key: "/account/settings/",
      url: "/account/settings/",
      feature: "account.settings",
      disabled: canAccess,
      label: <Link href={"/account/settings/"}>{t`Account settings`}</Link>,
    },
    {
      key: "/account/additional-fields/",
      url: "/account/additional-fields/",
      feature: "account.additional_fields",
      label: <Link href={"/account/additional-fields/"}>{t`Additional fields`}</Link>,
    },
    {
      key: "/account/api-access/",
      url: "/account/api-access/",
      feature: "account.api_access",
      label: <Link href={"/account/api-access/"}>{t`API access`}</Link>,
    },
    {
      key: "/account/user-permission/",
      url: "/account/user-permission/?permission=true",
      feature: "account.user_permission",
      disabled: canManageUsers,
      label: <Link href={"/account/user-permission/"}>{t`Users permissions`}</Link>,
    },
    {
      key: "/account/user-group-permission/",
      url: "/account/user-group-permission/?permission=true",
      feature: "account.user_group_permission",
      disabled: canManageUsers,
      label: <Link href={"/account/user-group-permission/"}>{t`User group permissions`}</Link>,
    },
  ];

  return menus.filter((menu) => configFeatures?.[menu.feature] === true);
};

export { availableMenus, availableUserMenuItems, availableAccountMenuItems };
