import { useEffect, useState } from "react";

import { theme } from "antd/lib";
import { flatten } from "components/antd/utils";

import { isEmpty } from "lodash";

const useTransformToken = (designTokens: any, appTheme: "light" | "dark") => {
  const [tokens, setTokens] = useState({});

  const { token } = theme.useToken();

  useEffect(() => {
    if (isEmpty(tokens)) {
      const seedPath = `${appTheme}/seed` as "light/seed" | "dark/seed";
      const colorsPath = `${appTheme}/colors` as "light/colors" | "dark/colors";
      const aliasPath = `${appTheme}/alias` as "light/alias" | "dark/alias";
      const mapPath = `${appTheme}/map` as "light/map" | "dark/map";

      const data: { [key: string]: any } = {
        ...token,
        ...designTokens[seedPath],
        ...designTokens[colorsPath],
        ...designTokens[aliasPath],
        ...designTokens[mapPath],
      };

      setTokens(flatten(data));
    }
  }, []);

  return tokens;
};

export { useTransformToken };
