import React from "react";

import { Row, Spin, SpinProps } from "antd";
import styled from "styled-components";

const StyledSpinner = styled(Spin)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.53);
  z-index: 1;

  .ant-spin-text {
    color: #353d48 !important;
  }
`;

const StyledContainer = styled(Row)`
  padding: 50px 0;

  .ant-spin-text {
    color: #353d48 !important;
  }
`;

export const Spinner = () => {
  return <StyledSpinner size={"large"} />;
};

/*
 * Used as a page to show loading state. it fills the entire page
 * */

const PageLoader = () => {
  return <Spinner />;
};

/*
 * Used as a component within another component to show loading state. it fills its parent container
 * */

const LoadingIndicator = (props: SpinProps) => {
  return (
    <StyledContainer justify="center">
      <Spin {...props} />
    </StyledContainer>
  );
};
export { LoadingIndicator };

export default PageLoader;
