import { useAtom, useAtomValue } from "jotai";

import { initializeSessionEffectAtom, loadingSessionAtom, userSessionAtom } from "atoms/session";

const useInitializeAppSession = () => {
  useAtom(initializeSessionEffectAtom);

  const isLoading = useAtomValue(loadingSessionAtom);
  const session = useAtomValue(userSessionAtom);

  return { isLoading, session };
};

export default useInitializeAppSession;
