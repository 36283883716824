import React, { ReactNode, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { Configuration } from "types";

interface IntercomProps {
  children: ReactNode;
  configuration?: Configuration | null;
  language: string;
  shouldLoad: boolean;
}

const Intercom = ({ children, configuration, language, shouldLoad }: IntercomProps) => {
  const { boot } = useIntercom();

  useEffect(() => {
    const intercomProps = {
      company: { companyId: configuration?.account?.id || "", name: configuration?.account?.name },
      email: configuration?.user?.email,
      userId: configuration?.user?.id,
      name: configuration?.user?.name_display,
      languageOverride: language,
      hideDefaultLauncher: true,
      alignment: "left",
    };

    if (shouldLoad && configuration?.account?.id) {
      boot(intercomProps);
    }
  }, [configuration]);

  return <>{children}</>;
};

export default Intercom;
