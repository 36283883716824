// db.ts
import Dexie from "dexie";

const DATABASE_NAME = "navirec-data-layers";
const DATABASE_VERSION = 1.123;

const db: any = new Dexie(DATABASE_NAME);

db.version(DATABASE_VERSION).stores({
  dataLayer: "id, account",
  dataLayerFeature: "id, data_layer_id, data_layer_type, account",
});

const dropDatabase = (cb?: () => void) => {
  db.delete()
    .then(() => {
      cb && cb();
    })
    .catch(() => {
      console.error("Could not delete database");
    });
};

export { db, dropDatabase };
