import { ReactNode } from "react";

import { Layout } from "antd";
import styled from "styled-components";

import PublicNavigation from "components/navigations/public";

const Container = styled(Layout)`
  height: 100%;
  width: 100%;
  min-height: 100vh;
`;

const Content = styled(Layout.Content)`
  height: 100%;
  display: grid;
  align-items: center;
`;

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => {
  return (
    <Container>
      <PublicNavigation />
      <Content>{children}</Content>
    </Container>
  );
};

export default PublicLayout;
