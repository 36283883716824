import { createJSONStorage } from "jotai/utils";

import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";

/*This utility function is to disable stringify for strings value when using atomWithStorage.*/
const stringStorage: SyncStorage<any> = createJSONStorage();
stringStorage.setItem = (key: string, newValue) =>
  typeof localStorage !== "undefined" && localStorage.setItem(key, newValue as string);
stringStorage.getItem = (key: string) => (typeof localStorage !== "undefined" ? localStorage.getItem(key) : "");

export { stringStorage };
