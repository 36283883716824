import React, { ReactNode, useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Trans } from "@lingui/macro";
import { Button, Col, Layout, Menu, Popover, Row, Typography } from "antd";
import { isEmpty } from "lodash";
import styled from "styled-components";

import ChatButton from "components/chat-button";
import ChangeAccount from "components/navigations/account";
import { useOneSignalNotification } from "components/navigations/one-signal";
import ProfileMenu from "components/navigations/profile-menu";
import SwitchLanguage from "components/navigations/switch-language";
import TachographStatus from "components/navigations/tachograph-status";
import UsersAccountMenu from "components/navigations/users-account-menu";
import { availableMenus } from "components/navigations/utils";

import { useApplicationContext } from "contexts/app";

import GSMTasksLogo from "icons/gsmtasks-logo";
import HelpIcon from "icons/help";
import LogoIcon from "icons/logo";

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 70px);
`;

const Container = styled(Layout)`
  height: 100vh;
  overflow: hidden;

  .ant-layout-sider-children {
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  }
`;

const LogoContainer = styled(Row)`
  margin: 16px 0 0 0;
`;

const SubmenuTitle = styled(Typography.Title)`
  margin-left: 12px;
`;

const MainMenu = styled(Layout.Sider)`
  &.ant-layout-sider-collapsed {
    flex: 0 0 52px !important;
    max-width: 52px !important;
    min-width: 52px !important;
    width: 52px !important;
  }
  .ant-menu-item {
    padding: 3px 0 0 13px;
  }
`;

const SubMenu = styled(Layout.Sider)`
  padding: 8px 0;

  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;

  &.ant-layout-sider-collapsed {
    flex: 0 0 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
    padding: 0 !important;
  }

  .ant-menu {
    border: none !important;
  }

  .ant-menu-item-selected {
    background: ${(props) => props.theme.colorBgTextHover};
  }

  border-right: 1px solid ${(props) => props.theme.colorBorder} !important;
  background: ${(props) => props.theme.colorBgContainer} !important;
`;

const SubMenuHeader = styled.div`
  padding: 0 16px;
`;

interface AppLayoutProps {
  children: ReactNode;
  showSubmenu?: boolean;
}

const Navigations = ({ children, showSubmenu }: AppLayoutProps) => {
  const [selectedMenu, setSelectedMenu] = useState("");

  useOneSignalNotification();
  const { configuration, collapsedSidebar } = useApplicationContext();
  const router = useRouter();

  const currentRoute = router.asPath;
  const paths = currentRoute.split("/").filter(Boolean);
  const configFeatures = configuration?.features;
  const first = paths[0];
  const second = `/${paths[0]}/${(paths[1] && `${paths[1]}/`) || ""}`;

  /* First and second level menu navigations items */
  let menuItems: any = availableMenus(configFeatures);
  let submenu = menuItems.find((menu: { key: string }) => menu.key === `/${first}/`);
  let secondLevelMenuItems = submenu?.children || [];
  const secondLevelMenu = secondLevelMenuItems.find((m: { key: string }) => m.key === second);
  const menuItemsWithoutChildren = menuItems.map((item: { key: string }) => {
    return { ...item, children: undefined };
  });

  const showUserAccountMenu = first === "user" || first === "account";

  useEffect(() => {
    if (isEmpty(submenu?.key)) {
      setSelectedMenu("");
    }
  }, [submenu?.key]);

  return (
    <Container>
      <MainMenu trigger={null} collapsible collapsed={true}>
        <LogoContainer align={"middle"} justify={"center"}>
          <Link href={"/map/"}>
            <LogoIcon />
          </Link>
        </LogoContainer>
        <MenuContainer>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenu || submenu?.key]}
            items={menuItemsWithoutChildren}
            onClick={(v) => {
              setSelectedMenu(v.key);
            }}
          />
          <Row justify={"center"} align={"middle"} gutter={[4, 8]}>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <Popover
                  trigger={"click"}
                  placement={"rightTop"}
                  arrow={{ pointAtCenter: true }}
                  overlayStyle={{ maxWidth: 300 }}
                  content={
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Typography.Text strong>
                          <Trans>Need to manage delivery & field service? </Trans>
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <a href={"https://gsmtasks.com/"} target={"_blank"}>
                          <Button type={"primary"}>Try GSMtasks</Button>
                        </a>
                      </Col>
                    </Row>
                  }
                >
                  <GSMTasksLogo />
                </Popover>
              </Row>
            </Col>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <TachographStatus />
              </Row>
            </Col>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <SwitchLanguage reloadApp={true} />
              </Row>
            </Col>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <a
                  href={"https://intercom.help/navirec"}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={""}
                  data-testid="help-menu"
                >
                  <HelpIcon />
                </a>
              </Row>
            </Col>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <ChatButton />
              </Row>
            </Col>
            <Col span={24}>
              <Row align={"middle"} justify={"center"}>
                <ProfileMenu data-testid="profile-menu" />
              </Row>
            </Col>
          </Row>
        </MenuContainer>
      </MainMenu>
      {!collapsedSidebar && showSubmenu !== false && (
        <SubMenu trigger={null} collapsible data-testid={"submenu-navigation"}>
          <SubMenuHeader>
            <ChangeAccount />
            <SubmenuTitle level={4}>{submenu?.label}</SubmenuTitle>
          </SubMenuHeader>
          {showUserAccountMenu ? (
            <UsersAccountMenu />
          ) : (
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[secondLevelMenu?.key || submenu?.key || ""]}
              defaultSelectedKeys={[secondLevelMenu?.key || ""]}
              items={secondLevelMenuItems}
              onClick={(v) => {
                router.push(v.key);
              }}
            />
          )}
        </SubMenu>
      )}
      <Layout>{children}</Layout>
    </Container>
  );
};

export default Navigations;
