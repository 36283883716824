import { t } from "@lingui/macro";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import relativeTime from "dayjs/plugin/relativeTime";
import tz from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { isEmpty } from "lodash";

const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm";
const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

export const initDayJs = async (lang: string) => {
  let locale = lang;
  if (locale == "en") {
    // use uk time format for english
    locale = "en-gb";
  }

  const l = await require(`dayjs/locale/${locale}.js`);

  dayjs.locale({ ...l, weekStart: 1 }); // set start of week to monday

  dayjs.extend(updateLocale);
  dayjs.extend(advancedFormat);
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  dayjs.extend(tz);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);
  dayjs.extend(customParseFormat);
  dayjs.extend(objectSupport);
};

const dateFromNow = (time: number) => {
  return dayjs.unix(time).fromNow(true);
};

const dateToNow = (time: number) => {
  return dayjs.unix(time).toNow(true);
};

const formatDateTime = (date: string | number, withTz?: boolean) => {
  if (!date) {
    return "";
  }

  if (withTz) {
    return dayjs.tz(dayjs(date)).format(DEFAULT_DATETIME_FORMAT);
  }

  return dayjs(date).format(DEFAULT_DATETIME_FORMAT);
};

const formatDate = (date: string | number | undefined, format?: string, withTz?: boolean) => {
  if (!date) {
    return "";
  }

  if (withTz) {
    return dayjs.tz(dayjs(date)).format(format || DEFAULT_DATE_FORMAT);
  }

  return dayjs(date).format(format || DEFAULT_DATE_FORMAT);
};

const formatDateAsTimeline = (date: Dayjs | string | number | undefined, withTz?: boolean) => {
  if (!date) {
    return "";
  }

  if (withTz) {
    return dayjs.tz(dayjs(date)).format(`dddd, ${DEFAULT_DATE_FORMAT}`);
  }

  return dayjs(date).format(`dddd, ${DEFAULT_DATE_FORMAT}`);
};

const formatTime = (time?: string | number, withTz?: boolean) => {
  if (!time) {
    return "";
  }

  if (withTz) {
    return dayjs.tz(dayjs(time)).format(DEFAULT_TIME_FORMAT);
  }

  return dayjs(time).format(DEFAULT_TIME_FORMAT);
};

const disabledHoursFromNow = () => {
  const hours = [];

  for (let i = 0; i <= 23; i += 1) {
    if (i > dayjs().hour()) {
      hours.push(i);
    }
  }

  return hours;
};

const disabledMinutesFromNow = () => {
  const minutes = [];

  for (let i = 0; i <= 59; i += 1) {
    if (i > dayjs().minute()) {
      minutes.push(i);
    }
  }

  return minutes;
};

const disableDateFromNow = (curr: { isAfter: (arg0: dayjs.Dayjs) => any }) => {
  return curr.isAfter(dayjs());
};

const convertDuration = (duration: number, fromUnit: string, toUnit: string) => {
  const units: { [key: string]: number } = {
    seconds: 1,
    minutes: 60,
    hours: 3600,
    days: 86400,
  };

  if (!units.hasOwnProperty(fromUnit) || !units.hasOwnProperty(toUnit)) {
    return 0;
  }

  if (duration < 0) {
    return 0;
  }

  return Number((duration * units[fromUnit]) / units[toUnit]);
};

const durationToHoursAndMinutes = (duration: number, unit: string) => {
  const conversionFactors: { [key: string]: number } = {
    seconds: 1 / 3600, // 1 hour = 3600 seconds
    minutes: 1 / 60, // 1 hour = 60 minutes
    hours: 1, // 1 hour = 1 hour
  };

  const hours = duration * conversionFactors[unit];
  const remainingMinutes = (hours % 1) * 60;

  return { hours: Math.floor(hours), minutes: Math.round(remainingMinutes) };
};

const durationFromNow = (end: number | string, from?: string | number) => {
  // @ts-ignore
  const duration = dayjs.duration(dayjs(from).diff(dayjs(end)), "milliseconds");
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) - hours * 60;
  const seconds = Math.floor(duration.asSeconds());

  switch (true) {
    case days >= 1 && hours > 24:
      const newHours = days * 24 - hours;
      return days + " " + t`d` + " " + Math.abs(newHours) + " " + t` h `;
    case hours > 0 && mins > 0:
      return hours + " " + t`h` + " " + mins + " " + t`min`;
    case hours > 0:
      return hours + " " + t`h`;
    case mins > 0:
      return mins + " " + t`min`;
    case seconds > 0:
      return seconds + " " + t`sec`;
  }
};

const getDurationAsComponent = ({
  start,
  end,
  Wrapper,
  ValueWrapper,
  UnitWrapper,
  wrapperProps = {},
  unitProps = {},
  valueProps = {},
}: {
  start: number | string;
  end: string | number;
  Wrapper: any;
  ValueWrapper: any;
  UnitWrapper: any;
  wrapperProps?: Object;
  unitProps?: Object;
  valueProps?: Object;
}) => {
  const duration = dayjs.duration(dayjs(start).diff(dayjs(end)), "milliseconds");

  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) - hours * 60;
  const days = Math.floor(duration.asDays());

  if (days >= 1 && hours > 24) {
    const newHours = days * 24 - hours;
    return (
      <Wrapper {...wrapperProps}>
        <ValueWrapper {...valueProps}>{`${days}`}</ValueWrapper>
        <UnitWrapper {...unitProps}>{`${t`d`}`}</UnitWrapper>
        <ValueWrapper {...valueProps}>{`${Math.abs(newHours)}`}</ValueWrapper>
        <UnitWrapper {...unitProps}>{t`h`}</UnitWrapper>
      </Wrapper>
    );
  }

  if (hours === 0) {
    return (
      <Wrapper {...wrapperProps}>
        <ValueWrapper {...valueProps}>{`${mins}`}</ValueWrapper>
        <UnitWrapper {...unitProps}>{t`min`}</UnitWrapper>
      </Wrapper>
    );
  } else {
    if (mins) {
      return (
        <Wrapper {...wrapperProps}>
          <ValueWrapper {...valueProps}>{`${hours}`}</ValueWrapper>
          <UnitWrapper {...unitProps}>{t`h`}</UnitWrapper>
          <ValueWrapper {...valueProps}>{`${mins}`}</ValueWrapper>
          <UnitWrapper {...unitProps}>{t`min`}</UnitWrapper>
        </Wrapper>
      );
    }
  }

  return (
    <Wrapper {...wrapperProps}>
      <ValueWrapper {...valueProps}>{`${hours}`}</ValueWrapper>
      <UnitWrapper {...unitProps}>{t`h`}</UnitWrapper>
    </Wrapper>
  );
};

const parseDurationAsSeconds = (val: string | undefined) => {
  if (!val) {
    return 0;
  }

  let seconds = 0;

  // Split on space to separate days from the time (if present)
  const [dayPart, timePart] = val.includes(" ") ? val.split(" ") : [undefined, val];

  const days = dayPart ? parseInt(dayPart) : 0;

  const block = timePart.split(":");
  const hours = block?.[0];
  const minutes = block?.[1];
  const sec = block?.[2];

  seconds += days * 86400; // 1 day = 86400 seconds

  if (hours) {
    seconds += parseInt(hours) * 3600;
  }
  if (minutes) {
    seconds += parseInt(minutes) * 60;
  }
  if (sec) {
    seconds += parseInt(sec);
  }

  return seconds;
};

const getDurationUnit = (unit?: string) => {
  let dayJsUnit = "milliseconds";

  if (unit === "s") {
    dayJsUnit = "seconds";
  }

  if (unit === "min") {
    dayJsUnit = "minutes";
  }

  if (unit === "hr") {
    dayJsUnit = "hours";
  }

  return dayJsUnit;
};
const formattedDuration = (duration: number, unit = "seconds", format = "") => {
  const DURATION_FORMAT = {
    formatAsMinutes: `m [${t`min`}]`,
    formatAsHours: `H [${t`h`}] m [${t`min`}]`,
    formatAsDays: `D [${t`d`}] H [${t`h`}]`,
    options: {
      trim: true,
      useGrouping: false,
      usePlural: false,
    },
  };

  let durationFormat = DURATION_FORMAT.formatAsMinutes;
  if (duration > 3600) {
    durationFormat = DURATION_FORMAT.formatAsHours;
  }
  if (duration > 86400) {
    durationFormat = DURATION_FORMAT.formatAsDays;
  }

  return (
    dayjs
      /*@ts-ignore*/
      .duration(duration, unit)
      .format(
        format ? format : durationFormat,
        /*@ts-ignore*/
        DURATION_FORMAT.options
      )
  );
};

const getDuration = (duration: number) => {
  if (!duration) {
    return 0;
  }

  const DURATION_FORMAT = {
    formatAsMinutes: `m [${t`min`}]`,
    formatAsHours: `H [${t`h`}] m [${t`min`}]`,
    formatAsDays: `D [${t`d`}] H [${t`h`}]`,
    options: {
      trim: true,
      useGrouping: false,
      usePlural: false,
    },
  };

  return (
    dayjs
      /*@ts-ignore*/
      .duration(duration, "seconds")
      .format(
        duration > 3600 ? DURATION_FORMAT.formatAsHours : DURATION_FORMAT.formatAsMinutes,
        /*@ts-ignore*/
        DURATION_FORMAT.options
      )
  );
};

const convertToDates = (range: [string, string] | null) => {
  if (isEmpty(range)) return [];

  return range?.map((date) => dayjs(date));
};

export {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  getDurationAsComponent,
  formatDateAsTimeline,
  parseDurationAsSeconds,
  formattedDuration,
  durationFromNow,
  formatDateTime,
  formatDate,
  formatTime,
  dateFromNow,
  dateToNow,
  disabledHoursFromNow,
  disabledMinutesFromNow,
  disableDateFromNow,
  getDuration,
  convertDuration,
  getDurationUnit,
  durationToHoursAndMinutes,
  convertToDates,
};
