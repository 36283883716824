import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { availableMenus } from "components/navigations/utils";

const extractKeys = (menus: any[]) => {
  let paths: string[] = [];

  menus?.forEach((menu: { key: string; children: any[] }) => {
    paths.push(menu.key);

    if (menu.children) {
      const subPaths = menu.children?.map((row) => row.key);
      paths = paths.concat(subPaths);
    }
  });

  return paths;
};

const useCheckPagePermission = (permissions: any) => {
  const [isCheckingPermission, setIsCheckingPermission] = useState(false);

  const router = useRouter();

  const menus = extractKeys(availableMenus(permissions, false));
  const menusWithoutPermission = extractKeys(availableMenus(permissions, true));

  useEffect(() => {
    if (permissions) {
      setIsCheckingPermission(true);
      if (!menus.includes(router.asPath) && menusWithoutPermission.includes(router.asPath)) {
        router.push("/401");
      }

      setIsCheckingPermission(false);
    }
  }, [menus]);

  return { isCheckingPermission };
};

export default useCheckPagePermission;
