const HelpIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 19.7188C6.18594 19.7188 2.28125 15.8141 2.28125 11C2.28125 6.18594 6.18594 2.28125 11 2.28125C15.8141 2.28125 19.7188 6.18594 19.7188 11C19.7188 15.8141 15.8141 19.7188 11 19.7188Z"
        fill="white"
        fillOpacity="0.65"
      />
      <path
        d="M13.6156 6.42266C12.9125 5.80625 11.9844 5.46875 11 5.46875C10.0156 5.46875 9.0875 5.80859 8.38438 6.42266C7.65312 7.0625 7.25 7.92266 7.25 8.84375V9.02188C7.25 9.125 7.33437 9.20938 7.4375 9.20938H8.5625C8.66562 9.20938 8.75 9.125 8.75 9.02188V8.84375C8.75 7.81016 9.76016 6.96875 11 6.96875C12.2398 6.96875 13.25 7.81016 13.25 8.84375C13.25 9.57266 12.7344 10.2406 11.9352 10.5477C11.4383 10.7375 11.0164 11.0703 10.7141 11.5062C10.407 11.9516 10.2477 12.4859 10.2477 13.0273V13.5312C10.2477 13.6344 10.332 13.7188 10.4352 13.7188H11.5602C11.6633 13.7188 11.7477 13.6344 11.7477 13.5312V12.9992C11.7489 12.7717 11.8186 12.5498 11.9478 12.3625C12.077 12.1752 12.2596 12.0312 12.4719 11.9492C13.8547 11.4172 14.7477 10.1984 14.7477 8.84375C14.75 7.92266 14.3469 7.0625 13.6156 6.42266ZM10.0625 16.1562C10.0625 16.4049 10.1613 16.6433 10.3371 16.8192C10.5129 16.995 10.7514 17.0938 11 17.0938C11.2486 17.0938 11.4871 16.995 11.6629 16.8192C11.8387 16.6433 11.9375 16.4049 11.9375 16.1562C11.9375 15.9076 11.8387 15.6692 11.6629 15.4933C11.4871 15.3175 11.2486 15.2188 11 15.2188C10.7514 15.2188 10.5129 15.3175 10.3371 15.4933C10.1613 15.6692 10.0625 15.9076 10.0625 16.1562Z"
        fill="white"
        fillOpacity="0.65"
      />
    </svg>
  );
};

export default HelpIcon;
