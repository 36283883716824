import { useRouter } from "next/router";

import { t, Trans } from "@lingui/macro";
import { Button, Result } from "antd";
import styled from "styled-components";

import { TIMEOUT } from "utils/http-client-utils";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorPage = ({
  error,
}: {
  error: {
    message?: string;
    code: string;
  };
}) => {
  const router = useRouter();

  switch (error.code) {
    case "ECONNABORTED":
      return (
        <Container>
          <Result
            status="warning"
            title={t`Connection timed out`}
            subTitle={t`Failed to establish a connection after ${TIMEOUT}, try reloading app to reconnect. Please contact administrator if issue persist.`}
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>
            }
          />
        </Container>
      );
    case "notfound":
      return (
        <Container>
          <Result
            status="error"
            title={t`Page notfound`}
            subTitle={error.message || t`Sorry, the page you visited does not exist.`}
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.push("/");
                }}
              >
                <Trans>Go home</Trans>
              </Button>
            }
          />
        </Container>
      );
    case "unauthorized":
      return (
        <Container>
          <Result
            status="warning"
            title={t`Unauthorized`}
            subTitle={error.message || t`You do not have enough permission to view this page.`}
            extra={[
              <Button
                type="default"
                key="go-to-map"
                onClick={() => {
                  router.push("/");
                }}
              >
                <Trans>Go home</Trans>
              </Button>,
              <Button
                type="default"
                key="reload"
                onClick={() => {
                  router.back();
                }}
              >
                <Trans>Go back</Trans>
              </Button>,
            ]}
          />
        </Container>
      );
    case "ERR_BAD_REQUEST":
      return (
        <Container>
          <Result
            status="error"
            title={t`Bad request`}
            subTitle={error.message}
            extra={[
              <Button
                type="default"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>,
              <Button
                type="primary"
                key="login"
                onClick={() => {
                  router.push("/login");
                }}
              >
                <Trans>Login</Trans>
              </Button>,
            ]}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Result
            status="error"
            title={t`Could not start application`}
            subTitle={
              error.message ||
              t`An error occurred, try reloading app to restart. Please contact administrator if issue persist.`
            }
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>
            }
          />
        </Container>
      );
  }
};

export default ErrorPage;
