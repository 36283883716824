import validator from "validator";

export const extractUuidFromURL = (value: string | undefined = "") => {
  if (!value) {
    return "";
  }

  const values = value?.split("/");

  // @ts-ignore
  return values.find((v) => validator.isUUID(v));
};

export const isUUID = (value?: string) => {
  if (!value) {
    return false;
  }

  // @ts-ignore
  return validator.isUUID(value);
};

//TODO: check why this is needed, if it's needed let's move it out as there is no relation to UUID
export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
