import { ReactNode, useEffect, useState } from "react";

import { Typography } from "antd";

interface Countdown {
  deadlineInSeconds: number;
  message?: (time: number) => ReactNode;
}

const Countdown = ({ deadlineInSeconds, message }: Countdown) => {
  const [time, setTime] = useState(deadlineInSeconds);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [deadlineInSeconds]);

  if (message) {
    return <>{message(time)}</>;
  }

  return <Typography.Text>{time}</Typography.Text>;
};

export default Countdown;
