const ChatIcon = () => {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.94119 1C2.07486 1 1.37256 1.7023 1.37256 2.56863V16.6863C1.37256 17.5526 2.07486 18.2549 2.94119 18.2549H14.5293C15.7758 19.0474 17.5554 20.2578 18.6275 21L18.6275 2.56863C18.6275 1.7023 17.9252 1 17.0588 1H2.94119Z"
        stroke="white"
        strokeOpacity="0.65"
        strokeWidth="1.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25154 11.3989C5.71346 12.7211 7.74313 13.5492 9.99995 13.5492C12.2568 13.5492 14.2864 12.7211 15.7484 11.3989L16.8006 12.5623C15.0471 14.1482 12.6413 15.1179 9.99995 15.1179C7.35864 15.1179 4.95285 14.1482 3.19934 12.5623L4.25154 11.3989Z"
        fill="#38D379"
      />
    </svg>
  );
};

export default ChatIcon;
