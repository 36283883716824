import React from "react";

import { useRouter } from "next/router";

import { Col, Menu, Row, Typography } from "antd";
import { first } from "lodash";
import styled from "styled-components";

import NamedAvatar from "components/navigations/avatar";
import { getAvatarName } from "components/navigations/profile-menu";
import { availableAccountMenuItems, availableUserMenuItems } from "components/navigations/utils";

import { useApplicationContext } from "contexts/app";

const Container = styled(Row)`
  padding: 0 16px;

  .ant-menu,
  .ant-row {
    width: 100%;
  }
`;

const Header = styled(Row)`
  margin-left: 16px;
  width: 100% !important;
`;

const UsersAccountMenu = () => {
  const router = useRouter();
  const { configuration, session } = useApplicationContext();

  const user = configuration?.user;
  const account = configuration?.account;
  const accountName = account?.name || "";
  const features = configuration?.features;
  const name = `${user?.name_display || user?.email}`;
  const userMenuItems = availableUserMenuItems(features);
  const accountMenuItems = availableAccountMenuItems(features);

  const active = router.asPath.split("/").filter(Boolean);
  const activeMenu = `/${first(active)}/${active?.[1]}/`;

  return (
    <Container gutter={[0, 32]}>
      <Row gutter={[8, 8]} align={"middle"} justify={"space-between"}>
        <Header>
          <Col span={4}>
            <NamedAvatar id={session?.userId as string} name={getAvatarName(user)} />
          </Col>
          <Col span={20}>
            <Typography.Text strong ellipsis>
              {name}
            </Typography.Text>
          </Col>
        </Header>
        <Menu
          onClick={(v) => {
            const menu = userMenuItems.find((menu) => menu.key === v.key);
            if (menu) {
              router.push(menu.url);
            }
          }}
          selectedKeys={[activeMenu]}
        >
          {userMenuItems.map((item: { key: string | number | null | undefined; url: any; label: React.ReactNode }) => {
            return (
              <Menu.Item key={item.key} title={""}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </Row>
      <Row gutter={[8, 8]} align={"middle"} justify={"space-between"}>
        <Header>
          <Col span={4}>
            <NamedAvatar id={session?.userId as string} name={accountName.split(" ")?.[0] || accountName} />
          </Col>
          <Col span={20}>
            <Typography.Text strong ellipsis>
              {accountName}
            </Typography.Text>
          </Col>
        </Header>
        <Menu
          onClick={(v) => {
            const menu = accountMenuItems.find((menu) => menu.key === v.key);
            if (menu) {
              router.push(menu.url);
            }
          }}
          selectedKeys={[activeMenu]}
        >
          {accountMenuItems.map(
            (item: { key: string | number | null | undefined; url: any; label: React.ReactNode }) => {
              return (
                <Menu.Item key={item.key} title={""}>
                  {item.label}
                </Menu.Item>
              );
            }
          )}
        </Menu>
      </Row>
    </Container>
  );
};
export default UsersAccountMenu;
