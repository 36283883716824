import React, { useState } from "react";
import OneSignal from "react-onesignal";

import router from "next/router";

import { LogoutOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { Button, Col, Divider, message, Popover, Row, Typography } from "antd";
import { isEmpty } from "lodash";
import styled from "styled-components";

import NamedAvatar from "components/navigations/avatar";
import { availableAccountMenuItems, availableUserMenuItems } from "components/navigations/utils";

import { useApplicationContext } from "contexts/app";

import { User } from "types";

import { onGetHijackedSession, onLogout, onResetHijackedSessionToken } from "utils/authentication-utils";

const Container = styled.div`
  width: 250px;

  a,
  .action,
  .log-out-icon {
    color: #353d48;

    svg {
      font-size: 16px;
    }

    :hover {
      color: ${(props) => props.theme.colorPrimary};
      cursor: pointer;
    }
  }
`;

export const getAvatarName = (user?: User) => {
  const firstName = user?.first_name || "";
  const lastName = user?.last_name || "";
  const nameDisplay = user?.name_display || "";
  const email = user?.email || "";

  switch (true) {
    case !isEmpty(firstName) && !isEmpty(lastName):
      return `${firstName} ${lastName}`;
    case !isEmpty(firstName):
      return firstName;
    case !isEmpty(lastName):
      return lastName;
    case !isEmpty(nameDisplay):
      return nameDisplay;
    default:
      return email;
  }
};

const ProfileMenu = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  const { configuration, session, httpClient } = useApplicationContext();

  const user = configuration?.user;
  const account = configuration?.account;
  const accountName = account?.name || "";
  const features = configuration?.features;
  const name = `${user?.name_display || user?.email}`;
  const userMenuItems = availableUserMenuItems(features);
  const accountMenuItems = availableAccountMenuItems(features);
  const hijackedSession = onGetHijackedSession();

  return (
    <Popover
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
      content={
        <Container>
          <Row gutter={[4, 8]} align={"middle"} justify={"space-between"}>
            <Col span={2}>
              <NamedAvatar id={session?.userId as string} name={getAvatarName(user)} />
            </Col>
            <Col span={21}>
              <Typography.Text strong ellipsis>
                {name}
              </Typography.Text>
            </Col>
            {userMenuItems.map(
              (item: { key: string | number | null | undefined; url: any; label: React.ReactNode }) => {
                return (
                  <Col span={24} key={item.key} onClick={() => setOpen(false)}>
                    {item.label}
                  </Col>
                );
              }
            )}
          </Row>
          <Divider />
          <Row gutter={[8, 8]} align={"middle"} justify={"space-between"}>
            <Col span={2}>
              <NamedAvatar id={session?.userId as string} name={accountName.split(" ")?.[0] || accountName} />
            </Col>
            <Col span={21}>
              <Typography.Text strong ellipsis>
                {accountName}
              </Typography.Text>
            </Col>
            {accountMenuItems.map(
              (item: { key: string | number | null | undefined; url: any; label: React.ReactNode }) => {
                return (
                  <Col span={24} key={item.key} onClick={() => setOpen(false)}>
                    {item.label}
                  </Col>
                );
              }
            )}
          </Row>
          <Divider />
          <Row
            align={"middle"}
            justify={"space-between"}
            onClick={async () => {
              message.loading(t`Logging out of app...`, 0);
              await httpClient.post("logout/", {
                errorHandler: () => {
                  message.destroy();
                },
                successHandler: async () => {
                  if (hijackedSession) {
                    onResetHijackedSessionToken();
                  } else {
                    OneSignal.removeExternalUserId();
                    onLogout();
                  }
                  message.destroy();
                  router.push("/login");
                },
              });
            }}
            className={"action"}
          >
            <Col span={2}>
              <Row justify={"center"} align={"middle"}>
                <LogoutOutlined />
              </Row>
            </Col>
            <Col span={21}>
              {onGetHijackedSession() ? <span>{t`Release hijacked session`}</span> : <span>{t`Log out`}</span>}
            </Col>
          </Row>
        </Container>
      }
    >
      <Button type="link" icon={<NamedAvatar id={session?.userId as string} name={getAvatarName(user)} />} {...props} />
    </Popover>
  );
};

export default ProfileMenu;
