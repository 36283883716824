import { isArray } from "lodash";
import get from "lodash/get";

const Parser = require("expr-eval").Parser;
const expandTemplate = require("expand-template")({ sep: "{}" });

const shadow = ["dropShadow", "boxShadow"];

const isNumeric = (num: string) => {
  return typeof Number(num) === "number" && !isNaN(Number(num));
};

const removeHash = (hex: string) => {
  const arr = hex.split("");
  arr.shift();

  return arr.join("");
};

const expand = (hex: string) => {
  return hex
    .split("")
    .reduce(function (accum: any[], value: string) {
      return accum.concat([value, value]);
    }, [])
    .join("");
};

const hexToRgb = (hex: string) => {
  if (hex.charAt && hex.charAt(0) === "#") {
    hex = removeHash(hex);
  }

  if (hex.length === 3) {
    hex = expand(hex);
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b].toString();
};

const getMatched = (str: string, prefix: string, suffix: string) => {
  const start = str.indexOf(prefix);
  const end = str.indexOf(suffix);

  return str.substr(start + 1, end - start - 1);
};

const getAllMatched = (str: string) => {
  const results = [];
  const re = /{([^}]+)}/g;
  let text;

  while ((text = re.exec(str))) {
    results.push(text[1]);
  }

  return results;
};

const colorExtractor = (v: string, data: any): string => {
  // deprecate
  if (v.startsWith("{") && v.endsWith("}")) {
    const path = v.slice(1, -1);
    return colorExtractor(path, data);
  }

  if (v.includes("{") && v.includes("}")) {
    const path = getMatched(v, "{", "}");

    return v.replace(`{${path}}`, hexToRgb(colorExtractor(path, data)));
  }

  if (get(data, v)?.value) {
    return colorExtractor(get(data, v).value, data);
  }

  return v;
};

const operators = ["+", "-", "*", "/", "%", "**"];

const expandString = (tmpl: string, data: any) => {
  const paths: any = getAllMatched(tmpl);
  const values: { [key: string]: any } = {};

  paths.map((path: string) => {
    let getValue = get(data, path)?.value;
    if (typeof getValue === "string" && getValue.includes("{")) {
      getValue = expandString(getValue, data);
    }
    values[path] = getValue;
  });

  // handle rgb color
  if (tmpl.startsWith("rgb")) {
    Object.keys(values).forEach((key) => {
      values[key] = hexToRgb(values[key]);
    });
  }

  let result = expandTemplate(tmpl, values);

  if (operators.some((v) => tmpl.includes(v))) {
    result = Parser.parse(result).evaluate();
  }

  if (isNumeric(result)) {
    return Number(result);
  }

  return result;
};

type objectType = { [key: string]: any };

const expandObject = (obj: objectType | objectType[], data: objectType) => {
  const expand = (o: objectType) => {
    const results: objectType = {};

    Object.keys(o).forEach((key: string) => {
      const val = o[key];
      if (val.includes("{")) {
        results[key] = expandString(val, data);
      } else {
        if (isNumeric(val)) {
          results[key] = Number(val);
        } else {
          results[key] = val;
        }
      }
    });

    return results;
  };

  if (isArray(obj)) {
    return obj.map((o) => {
      return expand(o);
    });
  }

  return expand(obj);
};

const boxShadowToString = (obj: any) => {
  return [`${obj.x}px`, `${obj.y}px`, `${obj.blur}px`, `${obj.spread}px`, `rgba(${hexToRgb(obj.color)},0.05)`].join(
    " "
  );
};

const flatten = (data: any) => {
  const values: { [key: string]: any } = {};
  Object.keys(data).map((key) => {
    let value = data[key].value;

    if (value) {
      switch (true) {
        case typeof value === "string" && value.includes("{"):
          values[key] = expandString(value, data);
          break;
        case typeof value == "object":
          let val: any = expandObject(value, data);

          switch (true) {
            case isArray(val):
              val = val
                .map((row: { [x: string]: string }) => {
                  if (shadow.includes(row["type"])) {
                    return boxShadowToString(row);
                  }
                })
                .join(", ");
              break;
            case shadow.includes(val["type"]):
              val = boxShadowToString(val);
              break;
          }

          values[key] = val;
          break;
        default:
          if (isNumeric(value)) {
            values[key] = Number(value);
          } else {
            values[key] = value;
          }
      }
    }
  });

  return values;
};

export { flatten };
