import { useIntercom } from "react-use-intercom";

import { t } from "@lingui/macro";
import { Badge, Popconfirm } from "antd";
import { useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import styled from "styled-components";

import { countUnReadMessagesAtom } from "atoms/configurations";

import ChatIcon from "icons/chat-icon";

const Container = styled.div`
  cursor: pointer;
`;

export const showChatIconMovedAtom = atomWithStorage<boolean>("navirec-settings-show-chat-icon-msg", true, undefined, {
  getOnInit: true,
});
showChatIconMovedAtom.debugLabel = "showChatIconMoved";

const ChatButton = () => {
  const { isOpen, show, hide } = useIntercom();

  const [showChatIconMoved, setShowChatIconMovedAtom] = useAtom(showChatIconMovedAtom);
  const unreadMessages = useAtomValue(countUnReadMessagesAtom);

  return (
    <>
      <Popconfirm
        title={t`Send us a message`}
        description={t`We have moved the the chat button here.`}
        okText={t`Close`}
        open={showChatIconMoved}
        showCancel={false}
        onConfirm={() => setShowChatIconMovedAtom(false)}
        placement="right"
      >
        <Container
          onClick={() => {
            if (isOpen) {
              hide();
            } else {
              show();
            }
          }}
        >
          <Badge count={unreadMessages} size={"small"}>
            <ChatIcon />
          </Badge>
        </Container>
      </Popconfirm>
    </>
  );
};

export default ChatButton;
