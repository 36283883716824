const TachographCardStatus = {
  ONLINE: "online",
  OFFLINE: "offline",
  PARTLY_ONLINE: "partly-online",
  HIDDEN: "hidden",
};

const getStatusColor = (status: string) => {
  switch (status) {
    case TachographCardStatus.ONLINE:
      return "#38D379";
    case TachographCardStatus.OFFLINE:
      return "#FF5457";
    case TachographCardStatus.PARTLY_ONLINE:
      return "#FFAB00";
  }
};

const TachoCardStatus = ({ status }: { status: string }) => {
  if (status !== TachographCardStatus.HIDDEN) {
    return (
      <svg width="24" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3748 6.53138C12.1556 6.35608 11.8443 6.35608 11.6251 6.53138L6.62512 10.5314C6.42614 10.6906 6.34941 10.9581 6.43376 11.1985C6.5181 11.439 6.74512 11.5999 6.99994 11.5999H8.4001V15.8999H6.9999C6.66853 15.8999 6.3999 16.1685 6.3999 16.4999C6.3999 16.8313 6.66853 17.0999 6.9999 17.0999H16.9999C17.3313 17.0999 17.5999 16.8313 17.5999 16.4999C17.5999 16.1685 17.3313 15.8999 16.9999 15.8999H15.6001V11.5999H16.9999C17.2547 11.5999 17.4818 11.439 17.5661 11.1985C17.6505 10.9581 17.5737 10.6906 17.3748 10.5314L12.3748 6.53138ZM14.4001 11.5999L14.4001 15.8999H12.6001V11.5999H14.4001ZM15.2895 10.3999H8.7104L11.9999 7.76828L15.2895 10.3999ZM11.4001 11.5999V15.8999H9.6001V11.5999H11.4001Z"
          fill="white"
          fillOpacity="0.65"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4C0 3.44772 0.447715 3 1 3H23C23.5523 3 24 3.44772 24 4V20C24 20.5523 23.5523 21 23 21H1C0.447716 21 0 20.5523 0 20V4ZM1.5 19.5V4.5H22.5V19.5H1.5Z"
          fill="white"
          fillOpacity="0.65"
        />
        {status !== TachographCardStatus.HIDDEN && (
          <circle cx="23" cy="21" r="4" fill={getStatusColor(status)} strokeWidth="2" />
        )}
      </svg>
    );
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3748 6.53138C12.1556 6.35608 11.8443 6.35608 11.6251 6.53138L6.62512 10.5314C6.42614 10.6906 6.34941 10.9581 6.43376 11.1985C6.5181 11.439 6.74512 11.5999 6.99994 11.5999H8.4001V15.8999H6.9999C6.66853 15.8999 6.3999 16.1685 6.3999 16.4999C6.3999 16.8313 6.66853 17.0999 6.9999 17.0999H16.9999C17.3313 17.0999 17.5999 16.8313 17.5999 16.4999C17.5999 16.1685 17.3313 15.8999 16.9999 15.8999H15.6001V11.5999H16.9999C17.2547 11.5999 17.4818 11.439 17.5661 11.1985C17.6505 10.9581 17.5737 10.6906 17.3748 10.5314L12.3748 6.53138ZM14.4001 11.5999L14.4001 15.8999H12.6001V11.5999H14.4001ZM15.2895 10.3999H8.7104L11.9999 7.76828L15.2895 10.3999ZM11.4001 11.5999V15.8999H9.6001V11.5999H11.4001Z"
        fill="white"
        fillOpacity="0.65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 3.44772 0.447715 3 1 3H23C23.5523 3 24 3.44772 24 4V20C24 20.5523 23.5523 21 23 21H1C0.447716 21 0 20.5523 0 20V4ZM1.5 19.5V4.5H22.5V19.5H1.5Z"
        fill="white"
        fillOpacity="0.65"
      />
    </svg>
  );
};

export default TachoCardStatus;

export { TachographCardStatus };
