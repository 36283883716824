import React, { ReactNode } from "react";

import { AppProps as NextAppProps } from "next/dist/pages/_app";

import { NextComponentType, NextPage, NextPageContext } from "next";

import BlankLayout from "layout/blank";
import PrivateLayout from "layout/private";
import PublicLayout from "layout/public";

type LayoutKeys = "Blank" | "Public" | "NoSubMenu" | "Private";
type PrivateLayoutProps = {
  submenu?: boolean;
};

export interface AppProps extends NextAppProps {
  Component: NextComponentType<NextPageContext, any, any> & {
    Layout: LayoutKeys;
    PrivateLayoutProps?: PrivateLayoutProps;
  };
}

export type PageProps<P = {}, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
  PrivateLayoutProps?: PrivateLayoutProps;
};

interface Layout {
  children: ReactNode;
  layout: LayoutKeys;
  privateLayoutProps?: PrivateLayoutProps;
}
const Layout = ({ children, layout, privateLayoutProps }: Layout) => {
  switch (layout) {
    case "Blank":
    case "NoSubMenu":
      return <BlankLayout>{children}</BlankLayout>;
    case "Public":
      return <PublicLayout>{children}</PublicLayout>;
    default:
      return <PrivateLayout submenu={privateLayoutProps?.submenu}>{children}</PrivateLayout>;
  }
};

export default Layout;
