import { Avatar } from "antd";
import { first, isEmpty, last, upperCase } from "lodash";
import styled from "styled-components";

const generateHexColor = function (str: string) {
  if (str.startsWith("#")) {
    return str;
  }

  let hash = 0;
  let colour = "#";

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }

  return colour;
};

const StyledAvatar = styled(Avatar)<{ id: string }>`
  color: #ffffff;
  font-size: 16px;
  background: ${(props) => generateHexColor(props.id || "#3DA066")};
`;

const getInitials = (name: string) => {
  if (isEmpty(name)) {
    return "";
  }

  const val = name.split(" ");

  if (val.length > 1) {
    return `${upperCase(first(first(val)))} ${upperCase(first(last(val)))}`;
  }

  return `${upperCase(first(first(val)))}`;
};

const NamedAvatar = ({ name, id }: { name: string; id: string }) => {
  const initial = getInitials(name);

  if (isEmpty(name)) {
    return <>-</>;
  }

  return (
    <StyledAvatar id={id} size={"small"}>
      {initial}
    </StyledAvatar>
  );
};

export default NamedAvatar;
