import React, { ReactNode } from "react";

import Navigations from "components/navigations";

interface PrivateLayoutProps {
  children: ReactNode;
  submenu?: boolean;
}

const PrivateLayout = ({ children, submenu }: PrivateLayoutProps) => {
  return <Navigations showSubmenu={submenu}>{children}</Navigations>;
};

export default PrivateLayout;
