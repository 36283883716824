import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";

import { location } from "atoms/environment";

import { useApplicationContext } from "contexts/app";

import { onGetHijackedSession } from "utils/authentication-utils";

export const useOneSignalNotification = () => {
  const [loaded, setLoaded] = useState(false);
  const { configuration } = useApplicationContext();
  const hijackedSession = onGetHijackedSession();

  // @ts-ignore TODO:should change API types definition
  const onesignalConfig: {
    external_user_id_auth_hash: string | undefined;
    external_user_id: string | undefined;
    app_id: string | undefined;
    safari_web_id: string | undefined;
  } = configuration?.services?.onesignal;
  const externalUserIdHash = onesignalConfig?.["external_user_id_auth_hash"];
  const externalUserId = onesignalConfig?.["external_user_id"];
  const oneSignalId = onesignalConfig?.["app_id"];
  const safariWebId = onesignalConfig?.["safari_web_id"];
  const isLocalHost = location?.href?.startsWith("http://localhost");

  useEffect(() => {
    if (onesignalConfig && !hijackedSession && !isLocalHost) {
      if (externalUserIdHash && externalUserId && oneSignalId && loaded === false) {
        OneSignal.init({
          appId: oneSignalId,
          safari_web_id: safariWebId,
          notifyButton: {
            enable: false,
          },
        }).then(async () => {
          setLoaded(true);
          console.info("onesignal: initialized");
          const enabled = await OneSignal.isPushNotificationsEnabled();
          if (!enabled) {
            console.log("onesignal: show prompt");
            await OneSignal.showNativePrompt();
          } else {
            console.log("onesignal: subscribed");
            await OneSignal.setSubscription(true);
          }
          await OneSignal.setExternalUserId(externalUserId, externalUserIdHash);
        });
      }
    }
  }, [externalUserId, externalUserIdHash, loaded, oneSignalId, onesignalConfig, safariWebId]);
};
