import styled from "styled-components";

const StyledSVG = styled.svg`
  :hover {
    cursor: pointer;
  }
`;

const GSMTasksLogo = ({ ...props }) => {
  return (
    <StyledSVG width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.89512 19.5281C7.77527 19.8139 7.49198 20 7.17684 20C6.8617 20 6.57757 19.8139 6.45856 19.5281L0.749191 5.88246C0.623471 5.58241 0.707285 5.23626 0.957887 5.02337C1.10288 4.901 1.28475 4.83731 1.46747 4.83731C1.60157 4.83731 1.73567 4.87083 1.8572 4.9404L6.45688 8.05491C6.45688 8.05491 6.85835 18.0907 7.17517 18.0907C7.33776 18.0907 7.89345 8.05491 7.89345 8.05491L14.1426 0.102684C14.4309 -0.0607522 14.7921 -0.0263886 15.0427 0.186498C15.2933 0.399384 15.3763 0.745533 15.2514 1.04559L7.89512 19.5281Z"
        fill="#60FA67"
      />
    </StyledSVG>
  );
};

export default GSMTasksLogo;
