import { Col, Row } from "antd";
import styled from "styled-components";

import SwitchLanguage from "components/navigations/switch-language";

import LogoLarge from "icons/logo-large";
import LogoWhiteBg from "icons/logo-white-bg";

const Container = styled.div`
  padding: 2.4%;
`;

const PublicNavigation = () => {
  return (
    <Container>
      <Row justify="space-between" align={"middle"}>
        <Col>
          <Row gutter={16} align={"middle"}>
            <Col>
              <a href="/">
                <LogoWhiteBg />
              </a>
            </Col>
            <Col>
              <SwitchLanguage />
            </Col>
          </Row>
        </Col>
        <Col>
          <a href="https://gsmtasks.com/" target="_blank" rel="noopener noreferrer">
            <LogoLarge />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicNavigation;
