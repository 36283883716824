import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";

import { Configuration } from "types";

import { onGetClientId } from "utils/authentication-utils";

const useInitSentryUser = (configuration?: Configuration) => {
  useEffect(() => {
    if (configuration?.user?.id) {
      Sentry.setUser({
        email: configuration?.user?.email,
        username: configuration?.user?.username,
        id: configuration?.user?.id,
        account: configuration?.account?.name,
        account_id: configuration?.account?.id,
        client_id: onGetClientId(),
      });
    }

    return () => {
      Sentry.setUser(null);
    };
  }, [configuration]);
};

export default useInitSentryUser;
