import React from "react";

import { Button, Col, Row, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import styled from "styled-components";
import useSWR from "swr";

import { useApplicationContext } from "contexts/app";

import TachoCardStatus, { TachographCardStatus } from "icons/tacho-card-status";

const REFRESH_INTERVAL = 30 * 10000;
const PAGE_SIZE = 100;

const getStatusColor = (status: boolean) => {
  switch (status) {
    case true:
      return "#38D379";
    case false:
      return "#FF5457";
    default:
      return "#9da8b7";
  }
};

const Color = styled.div<{ value: string }>`
  background: ${(props) => props.value};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const StyledText = styled(Typography.Text)`
  color: #ffffff;
`;

const CardStatusInfo = ({ statuses }: { statuses: any }) => {
  return statuses?.map((status: { number: number; online: boolean; name: string }) => {
    return (
      <div key={status.number}>
        <Row align="middle">
          <Col span={2}>
            <Color value={getStatusColor(status.online)} />
          </Col>
          <Col span={22}>
            <StyledText ellipsis>
              <span title={`${status.name}`}>
                {status.number} {status.name}
              </span>
            </StyledText>
          </Col>
        </Row>
      </div>
    );
  });
};

/*
* Card status rules:
  If there are 0 cards in that response, then hide the status bubble, keeping the card icon only.
  If there is exactly 1 card in the response, then the status bubble:

  null => no status bubble
  true => status green
  false => status red

  If there are 2 or more cards in the response, then the status bubble:

  All cards online: null => no status bubble
  All cards online: true => green
  At least one card online: true (but not all) => orange
  At least one card is online:false (others can be false or null) => red
* */

const getCardStatus = (cards: any[]) => {
  switch (true) {
    case isEmpty(cards):
    case cards?.every((card) => card.online === null):
      return TachographCardStatus.HIDDEN;
    case cards?.every((card) => card.online === true):
      return TachographCardStatus.ONLINE;
    case cards?.some((card) => card.online === true):
      return TachographCardStatus.PARTLY_ONLINE;
    default:
      return TachographCardStatus.OFFLINE;
  }
};

const TachographStatus = () => {
  const { session } = useApplicationContext();
  const { data: cards } = useSWR(
    `/tachograph_cards/?account=${session?.accountId}&type=company&active=True&page_size=${PAGE_SIZE}&ordering=number,name,id`,
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  );

  return (
    <Tooltip placement="left" title={<CardStatusInfo statuses={cards} />}>
      <Button
        type="link"
        icon={<TachoCardStatus status={getCardStatus(cards)} />}
        data-testid="tachograph-status-menu"
      />
    </Tooltip>
  );
};

export default TachographStatus;
export { getCardStatus };
