import axios from "axios";
import { atom } from "jotai";
import { isEmpty } from "lodash";

import { Environment } from "types";

import createHTTPClient from "utils/http-client-utils";
import { httpStatusCodes } from "utils/status-codes-utils";

interface EnvironmentAtom {
  environment?: Environment;
  error?: any;
}

const environmentURLPathname = "environment/?app=web";
const location: any = typeof window !== "undefined" && window.location ? window.location : "";
export const fetchEnvironmentUrl = async (location?: any) => {
  const response = await axios.get(`${location.origin}/api/config/`);

  const url = location && new URL(location.href);
  let baseUrl = "";

  if (response.status === httpStatusCodes.OK) {
    const data: { [key: string]: string } = response.data;
    const host: string = url.host;

    if (isEmpty(data[host])) {
      // hack to allow vercel route previews to DEV api
      if (host.includes("navirec-next")) {
        if (host.includes("live")) {
          baseUrl = "https://api.navirec.com/";
        } else {
          baseUrl = "https://api.navirec.namespace.ee/";
        }
      }
    } else {
      baseUrl = data[host];
    }
  }

  if (!baseUrl) {
    return environmentURLPathname;
  }

  return `${baseUrl}${environmentURLPathname}`;
};

const environmentAtom = atom<Promise<EnvironmentAtom | undefined>>(async (get) => {
  const httpClient = createHTTPClient("/", "");

  if (location) {
    const environmentUrl = await fetchEnvironmentUrl(location);

    if (environmentUrl) {
      try {
        const response = await httpClient.swrEnvironmentFetcher(environmentUrl);
        return {
          environment: response,
          error: null,
        };
      } catch (e) {
        return { environment: undefined, error: e };
      }
    }
  }

  return undefined;
});
environmentAtom.debugLabel = "environment";

export { environmentAtom, location };
