const LogoIcon = ({ ...props }) => {
  return (
    <svg width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9088 20.164C17.9088 20.164 16.5847 9.1421 9.23885 0.972412C1.79585 9.1421 0.568848 20.164 0.568848 20.164C0.568848 20.164 4.49795 18.3259 9.23885 18.3259C13.9797 18.3259 17.9088 20.164 17.9088 20.164ZM12.6882 15.0925C12.6882 15.0925 11.9664 11.0635 9.23885 7.67655C6.44633 10.9162 5.78949 15.0925 5.78949 15.0925C5.78949 15.0925 7.35268 14.5193 9.23885 14.5193C11.125 14.5193 12.6882 15.0925 12.6882 15.0925Z"
        fill="#38D379"
      />
      <path
        d="M5.74977 26.5341C6.93144 26.9165 8.15269 27.1475 9.37005 27.1475C10.5986 27.1475 11.7863 26.9119 12.8986 26.5208C13.5696 26.2849 14.0808 26.0429 14.3953 25.8627C15.1872 25.4091 15.4614 24.3994 15.0078 23.6076C14.5542 22.8157 13.5445 22.5415 12.7526 22.9951C12.6019 23.0814 12.2687 23.2392 11.8024 23.4031C11.0194 23.6784 10.1913 23.8427 9.37005 23.8427C8.5376 23.8427 7.64483 23.6738 6.76728 23.3899C6.23655 23.2181 5.84066 23.0502 5.64545 22.9522C4.82989 22.5427 3.83678 22.8719 3.42728 23.6874C3.01778 24.503 3.34695 25.4961 4.16251 25.9056C4.49816 26.0741 5.04497 26.306 5.74977 26.5341Z"
        fill="#9DA8B7"
      />
      <path
        d="M3.36068 32.0514C5.29313 32.7835 7.32028 33.2248 9.36499 33.2248C11.4166 33.2248 13.406 32.7803 15.267 32.0422C16.3848 31.5989 17.2262 31.1489 17.7233 30.827C18.4893 30.3309 18.7082 29.3078 18.2121 28.5418C17.7161 27.7758 16.693 27.557 15.927 28.053C15.8614 28.0955 15.7045 28.1887 15.4663 28.3161C15.0521 28.5375 14.576 28.761 14.0486 28.9702C12.5493 29.5648 10.9598 29.92 9.36499 29.92C7.76331 29.92 6.11696 29.5616 4.53148 28.961C3.97178 28.7489 3.4618 28.5222 3.01416 28.297C2.75472 28.1665 2.58075 28.07 2.50438 28.024C1.72236 27.5536 0.707075 27.8062 0.236676 28.5883C-0.233723 29.3703 0.0188954 30.3856 0.800915 30.856C1.32331 31.1702 2.20449 31.6134 3.36068 32.0514Z"
        fill="#9DA8B7"
      />
    </svg>
  );
};

export default LogoIcon;
