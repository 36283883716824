import { ReactNode } from "react";

interface BlankLayoutProps {
  children: ReactNode;
}

const BlankLayout = ({ children }: BlankLayoutProps) => {
  return <div>{children}</div>;
};

export default BlankLayout;
