const AppstoreIcon = ({ ...props }) => {
  return (
    <svg width="180" height="52" viewBox="0 0 180 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M179 46.003C179 48.7741 176.709 51.0188 173.874 51.0188H6.13243C3.2995 51.0188 1 48.7741 1 46.003V6.00345C1 3.23362 3.2995 0.981079 6.13243 0.981079H173.873C176.709 0.981079 178.999 3.23362 178.999 6.00345L179 46.003Z"
        fill="black"
      />
      <path
        d="M173.333 1.04162C176.42 1.04162 178.932 3.4905 178.932 6.5V45.5C178.932 48.5095 176.42 50.9584 173.333 50.9584H6.66667C3.58 50.9584 1.06833 48.5095 1.06833 45.5V6.5C1.06833 3.4905 3.58 1.04162 6.66667 1.04162H173.333ZM173.333 2.74817e-06H6.66667C3.00167 2.74817e-06 0 2.92663 0 6.5V45.5C0 49.0734 3.00167 52 6.66667 52H173.333C176.998 52 180 49.0734 180 45.5V6.5C180 2.92663 176.998 2.74817e-06 173.333 2.74817e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M40.1714 25.7191C40.1328 21.5292 43.6901 19.4908 43.8528 19.3959C41.8381 16.532 38.7154 16.1407 37.6181 16.1095C34.9954 15.8404 32.4514 17.6396 31.1154 17.6396C29.7528 17.6396 27.6954 16.1355 25.4781 16.1797C22.6248 16.2226 19.9554 17.8333 18.4914 20.3345C15.4701 25.4344 17.7234 32.9289 20.6181 37.0512C22.0661 39.0701 23.7581 41.3243 25.9728 41.245C28.1394 41.1579 28.9488 39.8982 31.5634 39.8982C34.1541 39.8982 34.9141 41.245 37.1728 41.1943C39.4981 41.1579 40.9621 39.1663 42.3594 37.1292C44.0328 34.8152 44.7048 32.5363 44.7314 32.4193C44.6768 32.4011 40.2154 30.741 40.1714 25.7191Z"
        fill="white"
      />
      <path
        d="M35.9048 13.3977C37.0701 11.9768 37.8674 10.0437 37.6461 8.08203C35.9594 8.15483 33.8501 9.21953 32.6354 10.6092C31.5608 11.8338 30.6008 13.841 30.8488 15.7286C32.7434 15.8664 34.6888 14.7965 35.9048 13.3977Z"
        fill="white"
      />
      <path
        d="M65.3997 13.0117C65.3997 14.5418 64.9291 15.6936 63.989 16.4671C63.1184 17.1808 61.881 17.5383 60.2784 17.5383C59.4837 17.5383 58.8037 17.5045 58.2344 17.4369V9.07658C58.977 8.95958 59.777 8.89978 60.641 8.89978C62.1677 8.89978 63.3184 9.22348 64.0944 9.87088C64.9637 10.6028 65.3997 11.6493 65.3997 13.0117ZM63.9264 13.0494C63.9264 12.0575 63.657 11.297 63.1184 10.7666C62.5797 10.2375 61.793 9.97228 60.757 9.97228C60.317 9.97228 59.9424 10.0009 59.6317 10.0607V16.4164C59.8037 16.4424 60.1184 16.4541 60.5757 16.4541C61.645 16.4541 62.4704 16.1642 63.0517 15.5844C63.6331 15.0046 63.9264 14.1596 63.9264 13.0494Z"
        fill="white"
      />
      <path
        d="M73.2126 14.3482C73.2126 15.2907 72.9366 16.0629 72.3846 16.6687C71.806 17.2914 71.0393 17.6021 70.082 17.6021C69.1593 17.6021 68.4246 17.3044 67.8766 16.7064C67.33 16.1097 67.0566 15.357 67.0566 14.4496C67.0566 13.5006 67.338 12.7219 67.9033 12.1174C68.4686 11.5129 69.2286 11.21 70.186 11.21C71.1086 11.21 71.85 11.5077 72.4113 12.1044C72.9446 12.6842 73.2126 13.433 73.2126 14.3482ZM71.7633 14.3924C71.7633 13.8269 71.638 13.342 71.3886 12.9377C71.0953 12.4489 70.678 12.2045 70.1353 12.2045C69.574 12.2045 69.1473 12.4489 68.854 12.9377C68.6033 13.342 68.4793 13.8347 68.4793 14.4171C68.4793 14.9826 68.6046 15.4675 68.854 15.8718C69.1566 16.3606 69.578 16.605 70.122 16.605C70.6553 16.605 71.074 16.3567 71.3753 15.8588C71.634 15.4467 71.7633 14.9579 71.7633 14.3924Z"
        fill="white"
      />
      <path
        d="M83.6867 11.3347L81.7201 17.4629H80.4401L79.6254 14.8018C79.4187 14.1375 79.2507 13.4771 79.1201 12.8219H79.0947C78.9734 13.4953 78.8054 14.1544 78.5894 14.8018L77.7241 17.4629H76.4294L74.5801 11.3347H76.0161L76.7267 14.248C76.8987 14.937 77.0401 15.5935 77.1534 16.2149H77.1787C77.2827 15.7027 77.4547 15.0501 77.6974 14.261L78.5894 11.336H79.7281L80.5827 14.1986C80.7894 14.8967 80.9574 15.5688 81.0867 16.2162H81.1254C81.2201 15.5857 81.3627 14.9136 81.5521 14.1986L82.3147 11.336H83.6867V11.3347Z"
        fill="white"
      />
      <path
        d="M90.9312 17.463H89.5339V13.953C89.5339 12.8714 89.1125 12.3306 88.2672 12.3306C87.8525 12.3306 87.5179 12.4788 87.2579 12.7765C87.0005 13.0742 86.8699 13.4252 86.8699 13.8269V17.4617H85.4725V13.0859C85.4725 12.5477 85.4552 11.964 85.4219 11.3322H86.6499L86.7152 12.2903H86.7539C86.9165 11.9926 87.1592 11.7469 87.4779 11.5506C87.8565 11.3218 88.2805 11.2061 88.7445 11.2061C89.3312 11.2061 89.8192 11.3907 90.2072 11.7612C90.6899 12.2149 90.9312 12.8922 90.9312 13.7918V17.463Z"
        fill="white"
      />
      <path d="M94.7847 17.4629H93.3887V8.52283H94.7847V17.4629Z" fill="white" />
      <path
        d="M103.01 14.3482C103.01 15.2907 102.734 16.0629 102.182 16.6687C101.603 17.2914 100.835 17.6021 99.8789 17.6021C98.9549 17.6021 98.2202 17.3044 97.6735 16.7064C97.1268 16.1097 96.8535 15.357 96.8535 14.4496C96.8535 13.5006 97.1349 12.7219 97.7002 12.1174C98.2655 11.5129 99.0255 11.21 99.9815 11.21C100.906 11.21 101.646 11.5077 102.208 12.1044C102.742 12.6842 103.01 13.433 103.01 14.3482ZM101.559 14.3924C101.559 13.8269 101.434 13.342 101.184 12.9377C100.892 12.4489 100.474 12.2045 99.9322 12.2045C99.3695 12.2045 98.9428 12.4489 98.6508 12.9377C98.4002 13.342 98.2762 13.8347 98.2762 14.4171C98.2762 14.9826 98.4015 15.4675 98.6508 15.8718C98.9535 16.3606 99.3749 16.605 99.9189 16.605C100.452 16.605 100.87 16.3567 101.171 15.8588C101.431 15.4467 101.559 14.9579 101.559 14.3924Z"
        fill="white"
      />
      <path
        d="M109.773 17.463H108.519L108.415 16.7571H108.376C107.947 17.32 107.335 17.6021 106.54 17.6021C105.947 17.6021 105.467 17.4162 105.105 17.047C104.777 16.7116 104.613 16.2943 104.613 15.799C104.613 15.0502 104.933 14.4795 105.577 14.0843C106.22 13.6891 107.124 13.4954 108.288 13.5045V13.3901C108.288 12.5828 107.853 12.1798 106.983 12.1798C106.363 12.1798 105.816 12.3319 105.344 12.6335L105.06 11.7391C105.644 11.3868 106.365 11.21 107.216 11.21C108.859 11.21 109.683 12.055 109.683 13.745V16.0018C109.683 16.6141 109.713 17.1016 109.773 17.463ZM108.323 15.357V14.4119C106.781 14.3859 106.011 14.798 106.011 15.6469C106.011 15.9667 106.099 16.2059 106.279 16.3658C106.459 16.5257 106.688 16.605 106.961 16.605C107.268 16.605 107.555 16.5101 107.816 16.3216C108.079 16.1318 108.24 15.8913 108.3 15.5962C108.315 15.5299 108.323 15.4493 108.323 15.357Z"
        fill="white"
      />
      <path
        d="M117.713 17.4629H116.473L116.408 16.4788H116.369C115.973 17.2276 115.298 17.602 114.35 17.602C113.593 17.602 112.962 17.3121 112.462 16.7323C111.962 16.1525 111.713 15.3998 111.713 14.4755C111.713 13.4836 111.984 12.6802 112.528 12.0666C113.054 11.4946 113.7 11.2086 114.468 11.2086C115.312 11.2086 115.902 11.4855 116.238 12.0406H116.265V8.52283H117.664V15.8119C117.664 16.4086 117.68 16.9585 117.713 17.4629ZM116.265 14.8785V13.8567C116.265 13.6799 116.252 13.5369 116.226 13.4277C116.148 13.1001 115.978 12.8245 115.721 12.6022C115.461 12.3799 115.148 12.2681 114.786 12.2681C114.265 12.2681 113.857 12.4696 113.557 12.8739C113.26 13.2782 113.109 13.7943 113.109 14.4248C113.109 15.0306 113.252 15.522 113.538 15.9003C113.841 16.3033 114.249 16.5048 114.76 16.5048C115.218 16.5048 115.585 16.3371 115.864 16.0004C116.133 15.6897 116.265 15.3153 116.265 14.8785Z"
        fill="white"
      />
      <path
        d="M129.664 14.3482C129.664 15.2907 129.388 16.0629 128.836 16.6687C128.257 17.2914 127.492 17.6021 126.533 17.6021C125.612 17.6021 124.877 17.3044 124.328 16.7064C123.781 16.1097 123.508 15.357 123.508 14.4496C123.508 13.5006 123.789 12.7219 124.354 12.1174C124.92 11.5129 125.68 11.21 126.638 11.21C127.56 11.21 128.302 11.5077 128.862 12.1044C129.396 12.6842 129.664 13.433 129.664 14.3482ZM128.216 14.3924C128.216 13.8269 128.09 13.342 127.841 12.9377C127.546 12.4489 127.13 12.2045 126.586 12.2045C126.026 12.2045 125.6 12.4489 125.305 12.9377C125.054 13.342 124.93 13.8347 124.93 14.4171C124.93 14.9826 125.056 15.4675 125.305 15.8718C125.608 16.3606 126.029 16.605 126.573 16.605C127.106 16.605 127.526 16.3567 127.828 15.8588C128.085 15.4467 128.216 14.9579 128.216 14.3924Z"
        fill="white"
      />
      <path
        d="M137.178 17.463H135.782V13.953C135.782 12.8714 135.361 12.3306 134.514 12.3306C134.099 12.3306 133.765 12.4788 133.506 12.7765C133.247 13.0742 133.118 13.4252 133.118 13.8269V17.4617H131.719V13.0859C131.719 12.5477 131.703 11.964 131.67 11.3322H132.897L132.962 12.2903H133.001C133.165 11.9926 133.407 11.7469 133.725 11.5506C134.105 11.3218 134.527 11.2061 134.993 11.2061C135.578 11.2061 136.066 11.3907 136.454 11.7612C136.938 12.2149 137.178 12.8922 137.178 13.7918V17.463Z"
        fill="white"
      />
      <path
        d="M146.582 12.3553H145.043V15.3323C145.043 16.0889 145.317 16.4672 145.858 16.4672C146.109 16.4672 146.317 16.4464 146.481 16.4035L146.517 17.437C146.241 17.5384 145.878 17.5891 145.431 17.5891C144.879 17.5891 144.45 17.4253 144.139 17.0977C143.827 16.7701 143.673 16.2189 143.673 15.4454V12.3553H142.754V11.3348H143.673V10.2116L145.042 9.80859V11.3335H146.581V12.3553H146.582Z"
        fill="white"
      />
      <path
        d="M153.978 17.4629H152.58V13.9789C152.58 12.8804 152.158 12.3305 151.314 12.3305C150.666 12.3305 150.224 12.649 149.981 13.286C149.94 13.4199 149.916 13.5837 149.916 13.7761V17.4616H148.52V8.52283H149.916V12.2161H149.942C150.382 11.544 151.013 11.2086 151.83 11.2086C152.409 11.2086 152.888 11.3932 153.268 11.7637C153.741 12.2252 153.978 12.9116 153.978 13.819V17.4629Z"
        fill="white"
      />
      <path
        d="M161.609 14.1089C161.609 14.3533 161.591 14.5587 161.557 14.7264H157.367C157.385 15.3322 157.585 15.7937 157.973 16.1135C158.328 16.3995 158.785 16.5425 159.345 16.5425C159.965 16.5425 160.531 16.4463 161.04 16.2526L161.259 17.199C160.663 17.4512 159.961 17.5773 159.149 17.5773C158.176 17.5773 157.409 17.2978 156.855 16.7388C156.297 16.1798 156.021 15.4297 156.021 14.4885C156.021 13.5642 156.279 12.7946 156.797 12.181C157.339 11.5258 158.071 11.1982 158.995 11.1982C159.899 11.1982 160.585 11.5258 161.049 12.181C161.424 12.701 161.609 13.3445 161.609 14.1089ZM160.276 13.7566C160.287 13.3523 160.195 13.0039 160.005 12.7101C159.763 12.3318 159.393 12.142 158.893 12.142C158.437 12.142 158.065 12.3266 157.781 12.6971C157.549 12.9922 157.412 13.3445 157.367 13.7566H160.276Z"
        fill="white"
      />
      <path
        d="M71.5269 40.9551H68.4989L66.8402 35.8734H61.0749L59.4949 40.9551H56.5469L62.2589 23.6547H65.7869L71.5269 40.9551ZM66.3402 33.7414L64.8402 29.2239C64.6815 28.7624 64.3842 27.6756 63.9455 25.9648H63.8922C63.7175 26.7006 63.4362 27.7874 63.0495 29.2239L61.5762 33.7414H66.3402Z"
        fill="white"
      />
      <path
        d="M86.2159 34.5643C86.2159 36.6859 85.6279 38.3629 84.4519 39.594C83.3985 40.6899 82.0905 41.2372 80.5292 41.2372C78.8439 41.2372 77.6332 40.647 76.8959 39.4666H76.8425V46.0381H73.9999V32.587C73.9999 31.2532 73.9639 29.8843 73.8945 28.4803H76.3945L76.5532 30.4576H76.6065C77.5545 28.9678 78.9932 28.2242 80.9239 28.2242C82.4332 28.2242 83.6932 28.8053 84.7012 29.9688C85.7119 31.1336 86.2159 32.665 86.2159 34.5643ZM83.3199 34.6657C83.3199 33.4515 83.0399 32.4505 82.4772 31.6627C81.8625 30.8411 81.0372 30.4303 80.0025 30.4303C79.3012 30.4303 78.6639 30.6591 78.0945 31.1102C77.5239 31.5652 77.1505 32.1593 76.9759 32.8951C76.8879 33.2383 76.8439 33.5191 76.8439 33.7401V35.8201C76.8439 36.7275 77.1292 37.4932 77.6999 38.1185C78.2705 38.7438 79.0119 39.0558 79.9239 39.0558C80.9945 39.0558 81.8279 38.6528 82.4239 37.8494C83.0212 37.0447 83.3199 35.9839 83.3199 34.6657Z"
        fill="white"
      />
      <path
        d="M100.931 34.5643C100.931 36.6859 100.343 38.3629 99.166 39.594C98.114 40.6899 96.806 41.2372 95.2447 41.2372C93.5593 41.2372 92.3487 40.647 91.6127 39.4666H91.5593V46.0381H88.7167V32.587C88.7167 31.2532 88.6807 29.8843 88.6113 28.4803H91.1113L91.27 30.4576H91.3233C92.27 28.9678 93.7087 28.2242 95.6407 28.2242C97.1487 28.2242 98.4087 28.8053 99.4193 29.9688C100.426 31.1336 100.931 32.665 100.931 34.5643ZM98.0353 34.6657C98.0353 33.4515 97.754 32.4505 97.1913 31.6627C96.5767 30.8411 95.754 30.4303 94.718 30.4303C94.0153 30.4303 93.3793 30.6591 92.8087 31.1102C92.238 31.5652 91.866 32.1593 91.6913 32.8951C91.6047 33.2383 91.5593 33.5191 91.5593 33.7401V35.8201C91.5593 36.7275 91.8447 37.4932 92.4127 38.1185C92.9833 38.7425 93.7247 39.0558 94.6393 39.0558C95.71 39.0558 96.5433 38.6528 97.1393 37.8494C97.7367 37.0447 98.0353 35.9839 98.0353 34.6657Z"
        fill="white"
      />
      <path
        d="M117.385 36.1035C117.385 37.5751 116.861 38.7724 115.809 39.6967C114.653 40.7068 113.044 41.2112 110.976 41.2112C109.066 41.2112 107.536 40.8524 106.377 40.1335L107.036 37.8234C108.284 38.5592 109.653 38.9284 111.145 38.9284C112.216 38.9284 113.049 38.6918 113.648 38.2212C114.244 37.7506 114.541 37.1188 114.541 36.331C114.541 35.629 114.296 35.0375 113.804 34.5578C113.314 34.0781 112.497 33.6322 111.356 33.2201C108.249 32.0904 106.697 30.4355 106.697 28.2593C106.697 26.8371 107.241 25.671 108.33 24.7636C109.416 23.8549 110.864 23.4012 112.674 23.4012C114.289 23.4012 115.63 23.6755 116.701 24.2228L115.99 26.4822C114.99 25.9518 113.86 25.6866 112.594 25.6866C111.594 25.6866 110.813 25.9271 110.253 26.4055C109.78 26.8332 109.542 27.3545 109.542 27.972C109.542 28.6558 109.813 29.2213 110.357 29.6659C110.83 30.0767 111.69 30.5213 112.938 31.001C114.465 31.6003 115.586 32.301 116.308 33.1044C117.026 33.9052 117.385 34.9075 117.385 36.1035Z"
        fill="white"
      />
      <path
        d="M126.784 30.5604H123.651V36.6171C123.651 38.1576 124.203 38.9272 125.31 38.9272C125.818 38.9272 126.239 38.8843 126.572 38.7985L126.651 40.9032C126.091 41.1073 125.354 41.21 124.44 41.21C123.318 41.21 122.44 40.8759 121.807 40.209C121.176 39.5408 120.859 38.4202 120.859 36.8459V30.5578H118.992V28.4778H120.859V26.1937L123.651 25.3721V28.4778H126.784V30.5604Z"
        fill="white"
      />
      <path
        d="M140.921 34.6151C140.921 36.5326 140.358 38.1069 139.235 39.338C138.058 40.6055 136.495 41.2373 134.547 41.2373C132.67 41.2373 131.175 40.6302 130.061 39.416C128.946 38.2018 128.389 36.6691 128.389 34.8218C128.389 32.8887 128.962 31.3053 130.113 30.0742C131.261 28.8418 132.81 28.2256 134.758 28.2256C136.635 28.2256 138.146 28.8327 139.286 30.0482C140.377 31.2273 140.921 32.7496 140.921 34.6151ZM137.971 34.7048C137.971 33.5543 137.719 32.5676 137.209 31.7447C136.613 30.7489 135.761 30.2523 134.657 30.2523C133.514 30.2523 132.646 30.7502 132.05 31.7447C131.539 32.5689 131.287 33.5712 131.287 34.7568C131.287 35.9073 131.539 36.894 132.05 37.7156C132.665 38.7114 133.523 39.208 134.631 39.208C135.717 39.208 136.569 38.701 137.183 37.6896C137.707 36.8511 137.971 35.854 137.971 34.7048Z"
        fill="white"
      />
      <path
        d="M150.162 30.9178C149.88 30.8671 149.58 30.8411 149.266 30.8411C148.266 30.8411 147.492 31.209 146.948 31.9461C146.475 32.5961 146.238 33.4177 146.238 34.4096V40.9551H143.396L143.423 32.4089C143.423 30.9711 143.387 29.662 143.316 28.4816H145.792L145.896 30.8684H145.975C146.275 30.0481 146.748 29.3877 147.396 28.8924C148.03 28.4465 148.714 28.2242 149.451 28.2242C149.714 28.2242 149.951 28.2424 150.162 28.2749V30.9178Z"
        fill="white"
      />
      <path
        d="M162.875 34.1275C162.875 34.6241 162.841 35.0427 162.771 35.3846H154.243C154.276 36.617 154.688 37.5595 155.48 38.2095C156.199 38.7906 157.128 39.0818 158.269 39.0818C159.532 39.0818 160.684 38.8855 161.72 38.4916L162.165 40.4156C160.955 40.9304 159.525 41.1865 157.876 41.1865C155.892 41.1865 154.335 40.6171 153.201 39.4796C152.071 38.3421 151.504 36.8146 151.504 34.8984C151.504 33.0173 152.031 31.4508 153.085 30.2015C154.189 28.8677 155.681 28.2008 157.559 28.2008C159.403 28.2008 160.799 28.8677 161.747 30.2015C162.497 31.261 162.875 32.5714 162.875 34.1275ZM160.164 33.4086C160.183 32.587 159.997 31.8772 159.612 31.2779C159.12 30.507 158.364 30.1222 157.347 30.1222C156.417 30.1222 155.661 30.4979 155.084 31.2519C154.611 31.8512 154.329 32.5701 154.243 33.4073H160.164V33.4086Z"
        fill="white"
      />
    </svg>
  );
};

export default AppstoreIcon;
