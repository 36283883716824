import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { environmentAtom } from "atoms/environment";
import { languageAtom } from "atoms/language";
import { userSessionAtom } from "atoms/session";

import createHTTPClient, { IHttpClient } from "utils/http-client-utils";

const httpClientAtom = atom<IHttpClient>(createHTTPClient("", ""));
httpClientAtom.debugPrivate = true;
httpClientAtom.debugLabel = "httpClient";

const initializeHttpClientEffectAtom = atomEffect((get, set) => {
  const environment = get(environmentAtom);
  const session = get(userSessionAtom);
  const language = get(languageAtom);

  environment.then((env) => {
    if (env?.environment?.navirec_api_url) {
      set(httpClientAtom, createHTTPClient(env.environment.navirec_api_url, session?.token, "", language));
    }
  });
});
initializeHttpClientEffectAtom.debugLabel = "initializeHttpClientEffect";
initializeHttpClientEffectAtom.debugPrivate = true;

export { initializeHttpClientEffectAtom, httpClientAtom };
