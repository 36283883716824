import { t } from "@lingui/macro";
import { Button, message, Typography } from "antd";
import { Axios } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import get from "lodash/get";

import Countdown from "components/countdown";

import { httpStatusCodes } from "utils/status-codes-utils";
import { isURLString } from "utils/url-utils";

const THROTTLE_DELAY = 60;

const addThrottledRequest = (
  path: string,
  throttle: { [key: string]: Dayjs | undefined },
  delay: number = THROTTLE_DELAY
) => {
  throttle[path] = dayjs().add(delay, "seconds");
};

const isRequestThrottled = (path: string, throttle: { [key: string]: Dayjs | undefined }) => {
  const delay = throttle[path];

  if (isEmpty(delay)) {
    return false;
  }

  if (delay.isBefore(dayjs())) {
    throttle[path] = undefined;
    return false;
  }

  return true;
};

const showThrottleMessage = (retryAfter: number, serviceName?: string, reload?: boolean) => {
  message.open({
    key: "429-error-message",
    type: "warning",
    content: (
      <Countdown
        deadlineInSeconds={retryAfter}
        message={(time) => {
          if (time) {
            return (
              t`Too many requests,` +
              " " +
              serviceName +
              " " +
              t`service would be available after` +
              " " +
              Math.round(time) +
              " " +
              t`seconds`
            );
          }

          if (reload) {
            return (
              <Typography.Text>
                {t`Service is now available. Please reload the app to continue`}{" "}
                <Button size="small" type="link" onClick={() => window.location.reload()}>{t`RELOAD`}</Button>
              </Typography.Text>
            );
          }
        }}
      />
    ),
    duration: reload ? 0 : retryAfter + 3, // retry after + 3 seconds delay before message is cleared,
  });
};

// TODO: in a different PR handle other errors in interceptors.response eg. onHandleError function.
const throttleRequest = (client: Axios, listOfThrottledRequest: { [key: string]: Dayjs | undefined } = {}) => {
  // intercept request and prevent making new request of path is already throttled
  client.interceptors.request.use(
    (config) => {
      const firstPath = getFirstPathFromURL(config?.url);

      if (isRequestThrottled(firstPath, listOfThrottledRequest)) {
        const throttledAfter = listOfThrottledRequest[firstPath];
        const retryAfter = throttledAfter?.diff(dayjs(), "seconds", true);
        if (retryAfter) {
          showThrottleMessage(retryAfter, firstPath, true);
        }

        return Promise.resolve() as any;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // intercept request and handle errors.
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const firstPath = getFirstPathFromURL(error?.config?.url);

      switch (error?.response?.status) {
        case httpStatusCodes.TOO_MANY_REQUESTS:
          const retryAfter = Number(get(error?.response?.headers, "retry-after") || THROTTLE_DELAY); // value in seconds

          addThrottledRequest(firstPath, listOfThrottledRequest, retryAfter);
          showThrottleMessage(retryAfter, firstPath, true);
          return Promise.reject(error);
        case httpStatusCodes.UNAUTHORIZED:
          window.location.href = "/login";
          return;
        default:
          return Promise.reject(error);
      }
    }
  );
};

const getFirstPathFromURL = (path?: string) => {
  if (isEmpty(path)) {
    return "";
  }

  if (isURLString(path)) return `/${new URL(path as string).pathname.split("/")[1]}/`;

  return `${path?.split("/")[0]}`;
};

export { throttleRequest, showThrottleMessage };
